<template>
  <v-dialog
    v-if="alarme.id"
    v-model="mostrar.mostrar"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        dark
        color="red"
      >
        <v-toolbar-title class="display-2 font-weight-black">
          Alarme #{{ alarme.id }}
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            text
            :loading="carregandoRecusar"
            @click="recusar()"
          >
            <v-icon
              v-text="'mdi-close'"
            />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container
          class="fill-height"
          fluid
        >
          <v-row
            justify="center"
            align="center"
          >
            <v-col
              cols="12"
              md="6"
              class="mt-5"
            >
              <carousel-imagens-leitura
                :imagens="alarme.imagens"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="text-center mt-5"
                >
                  <h1 class="display-3 black--text font-weight-black mb-2">
                    {{ alarme.leitura.placa }}
                  </h1>
                  <canvas
                    ref="canvas"
                    style="width: 100%;"
                  />
                  <p class="mb-0 display-2">
                    {{
                      alarme && alarme.veiculo && alarme.veiculo.veiculo_modelo ?
                        alarme.veiculo.veiculo_modelo.descricao + '/' + alarme.veiculo.veiculo_modelo.veiculo_marca.descricao :
                        '-'
                    }} •
                    {{
                      alarme && alarme.veiculo ?
                        (alarme.veiculo.ano_fabricacao ?? "-") + "/" + (alarme.veiculo.ano_modelo ?? "-") :
                        '-'
                    }} •
                    {{
                      alarme && alarme.veiculo && alarme.veiculo.cor ?
                        alarme.veiculo.cor.nome_portugues :
                        '-'
                    }}
                  </p>
                  <p class="display-2 mb-0">
                    {{
                      alarme && alarme.veiculo && alarme.veiculo.cidade ?
                        `${alarme.veiculo.cidade.descricao}/${alarme.veiculo.cidade.estado.abreviacao}` :
                        '-'
                    }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="text-center mt-5"
                >
                  <p class="display-1 mb-0 black--text">
                    {{ alarme.ponto_captura.descricao }}
                  </p>
                  <p class="subtitle">
                    {{ alarme.leitura.data_registro | data('DD/MM/YYYY HH:mm:ss') }}
                  </p>
                  <v-row v-if="restricao">
                    <v-col class="body-2">
                      <b>Data:</b> {{ restricao.dataOcorrencia | data }} <br>
                      <b>Natureza:</b> {{ restricao.naturezaOcorrencia }} <br>
                      <b>Município:</b> {{ `${restricao.municipioBO}/${restricao.ufBO}` }} <br>
                      <b>Histórico:</b> {{ restricao.historico }} <br>
                      <b>Sistema:</b> {{ restricao.sistema }} <br>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="alarme.ocorrencia">
                <v-col cols="12">
                  <v-sheet
                    class="pa-2"
                    color="grey lighten-3"
                    rounded
                  >
                    <p class="mb-1 font-weight-black body-1">
                      {{ alarme.ocorrencia.natureza_ocorrencia ? alarme.ocorrencia.natureza_ocorrencia.descricao : '' }}
                    </p>

                    <div class="mt-0 body-2">
                      {{ alarme.descricao }}
                    </div>

                    <div v-if="alarme.ocorrencia.anexos && alarme.ocorrencia.anexos.length > 0">
                      <p class="ma-0 mt-2 body-1">
                        <b>Anexos: </b>
                      </p>
                      <v-tooltip
                        v-for="(anexo, key) in alarme.ocorrencia.anexos"
                        :key="key"
                        :open-delay="200"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            class="mr-1"
                            label
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="download(anexo)"
                          >
                            {{ anexo.nome_original || anexo.nome_arquivo | stringLimit(20) }}
                          </v-chip>
                        </template>
                        <span>{{ anexo.nome_original || anexo.nome_arquivo }}</span>
                      </v-tooltip>
                    </div>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row v-if="assumido">
                <v-col cols="12">
                  <dialog-alarme-assinatura
                    :alarme="alarme"
                    :assinado="assinado"
                    v-model="alarme_controller"
                    @assinado="mostrar"
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="!assumido"
                class="mt-5"
              >
                <v-col class="text-center">
                  <v-btn
                    color="success"
                    :loading="carregandoAssumir"
                    @click="assumir()"
                  >
                    <v-icon
                      left
                      v-text="'mdi-check'"
                    />
                    Assumir Alarme
                  </v-btn>
                </v-col>
                <v-col class="text-center">
                  <v-btn
                    color="primary"
                    :loading="carregandoAssumir"
                    v-b-modal.modal-see
                  >
                    <v-icon
                      left
                      v-text="'mdi-magnify'"
                    />
                    Ocorrência
                  </v-btn>
                  <b-modal size="xl" class="modal-lg" id="modal-see" :title="alarme.ocorrencia.id.toString()" ok-only>
                    <ocorrencias-campos-see
                    :carregando-salvar="false"
                    :carregando-buscar="false"
                    :validacao-formulario-ocorrencia="false"
                    :ocorrencia="alarme.ocorrencia"
                    />
                </b-modal>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import alertasApi from '@/api/alertas'
  import cortarPlaca from '@/utils/cortar-placa'
  import { mapGetters } from 'vuex'

  export default {

    components: {
      CarouselImagensLeitura: () => import('@/components/CarouselImagensLeitura'),
      DialogAlarmeAssinatura: () => import('./DialogAlarmeAssinatura'),
      OcorrenciasCamposSee: () => import("@/views/pages/ocorrencias/OcorrenciasCamposSee"),
    },

    props: {
      value: {
        type: Object,
        default: ()=>{},
      },
      alarme: {
        type: Object,
        default: { ìd: 0 }
      }
    },

    data () {
      return {
        caminhoImagemLeitura: process.env.VUE_APP_URL_SERVIDOR,
        caminhoAnexos: process.env.VUE_APP_URL_SERVIDOR + 'storage/anexos_ocorrencia/',
        carregando: false,
        assinado: false,
        assumido: false,
        carregandoAssumir: false,
        carregandoRecusar: false,
        imagemComErro: false,
        show:true,
        alarme_controller:{
          mostrar:true,
          assumido:false,
          assinado:false
        },
      }
    },

    computed: {
      mostrar: {
        get () {
          return this.value
        },
        set (value) {
          document.querySelector('html').style.overflow = value ? 'hidden' : null
          this.$emit('input', value)
        },
      },
      restricao () {
        if (this.alarme.restricao==null) {
          return null
        }

        return JSON.parse(this.alarme.restricao)
      },
      isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
      },
      ...mapGetters({
        usuarioAdministrador: 'usuario/usuarioAdministrador',
        usuarioOperador: 'usuario/usuarioOperador',
        usuarioInteligencia: 'usuario/usuarioInteligencia',
      }),
    },

    watch: {
      carregando (val) {
        this.$emit('carregando', val)
      },
      alarme_controller (val) {
        this.mostrar = val
      },
    },

    mounted () {
      this.buscar()
    },

    methods: {
      async buscar () {
        try {
          this.carregando = true

          // let statusAssinado = this.statusAlarme(parseInt(this.alarme.status_resposta));
          // let statusAssumido = this.statusAlarme(parseInt(this.alarme.status_recebimento));
          let statusAssinado = this.isAssinado(parseInt(this.alarme.status_resposta));
          let statusAssumido = this.isAssumido(parseInt(this.alarme.status_recebimento));

          this.mostrar = {mostrar:true, assumido:statusAssumido, assinado:statusAssinado}
          this.assinado = statusAssinado;
          this.assumido = statusAssumido;

          // Tempo para renderizar o componente e tornar o canvas acessível
          setTimeout(() => {
            cortarPlaca(
              this.$refs.canvas,
              this.alarme.imagens[0].placas[0].posicao_placa,
              this.caminhoImagemLeitura + this.alarme.imagens[0].caminho_imagem,
            )
          }, 100)
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },

      async assumir () {
        this.mostrar={mostrar:true,assumido:true,assinado:false}
        try {
          this.carregandoAssumir = true

          await alertasApi.receber(this.alarme.id)

          this.assumido = true
          this.assinado = false
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'warning', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoAssumir = false
        }
      },
      async abrirOcorrencia () {

      },

      async recusar () {
        try {
          this.carregandoRecusar = true

          if (!this.assumido) {
            await alertasApi.recusar(this.alarme.id)
          }
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'warning', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoRecusar = false
          this.mostrar = {mostrar:false,assumido:true,assinado:false}
        }
      },

      async download (anexo) {
        const url = this.caminhoAnexos + anexo.nome_arquivo
        const win = window.open(url, '_blank')
        win.focus()
      },
      isAssumido(status_recebimento) {
        return status_recebimento >= 1;
      },
      isAssinado(status_resposta) {
        return status_resposta >= 1;
      },
      // statusAlarme (status) {
      //   if (this.usuarioAdministrador) {
      //     return (status >= 2)
      //   }
      //
      //   if (this.usuarioOperador && status >= 1) {
      //     return true
      //   }
      //
      //   if (this.usuarioInteligencia && status >= 2) {
      //     return true
      //   }
      //
      //   return false
      // },
    },
  }
</script>
